@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/**
 * Ant Design 스타일 Override
 */

/** List */

.ant-list-item {
  padding: 0.4rem 0.5rem 0.4rem 0.5rem;
  border-radius: 3px;
  box-shadow: 0px 1px 2px #c4c4c4;
}
.ant-list-item-meta-avatar {
  margin: 0px;
}

/** Menu */

.ant-menu-item-icon {
  min-width: 18px !important;
  margin: 0px !important;
  display: flex;
  align-items: center;
}

/** Modal */

.ant-modal-confirm-paragraph {
  max-width: unset !important;
}
.ant-modal-confirm-title {
  font-weight: 900 !important;
}
.ant-modal {
  .ant-modal-header {
    padding: 12px 16px;
    border-radius: 7px 7px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 0px !important;
    .ant-modal-title {
      font-weight: 700;
      font-size: 15px;
    }
  }
  .ant-modal-content {
    border-radius: 7px;
    padding: 0px !important;
    .ant-modal-confirm-content {
      width: 100%;
      margin-block-start: 0px !important;
      margin-inline-start: 0px !important;
      max-width: none !important;
    }
  }
}
.ant-modal-confirm {
  .ant-modal-header {
    border-radius: 4px 4px 0px 0px;
    .ant-modal-title {
      font-weight: 700;
    }
  }
  .ant-modal-footer {
    border-radius: 0px 0px 4px 4px;
  }
  .ant-modal-content {
    border-radius: 7px;
    .ant-modal-body {
      padding: 0 !important;
      border-radius: 7px;
      .ant-modal-confirm-body {
        text-align: center;
        .ant-modal-confirm-title {
          padding: 0.7rem;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        }
        .ant-modal-confirm-content {
          padding: 1rem;
        }
      }
      .ant-modal-confirm-btns {
        width: 100%;
        margin: 0;
        .ant-btn {
          min-height: 2.5rem;
          width: 100%;
          border-radius: 0 0 7px 7px;
        }
      }
    }
  }
}
.ant-modal-confirm-body > .anticon {
  display: none;
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  row-gap: 0px !important;
}

/** Form */

.ant-form-item-control-input {
  min-height: 0px !important;
}
.ant-form-item-no-colon {
  height: unset !important;
}
.ant-form-item {
  margin: 0 0 8px;
}

/** Grid */

.ant-col {
  min-height: unset;
}

/** Button */

.ant-btn:focus,
.ant-btn:hover {
  color: white;
}

/** Table */

td.ant-table-cell.best {
  background: #f6e58d;
  font-weight: 700;
}
td.ant-table-cell.highlight {
  background: #f6e58d;
  font-weight: 700;
}
tbody {
  .stretch-td {
    vertical-align: unset;
    min-height: 100%;
    position: relative;
  }
}

/** Card */

.ant-card {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #c4c4c4;
  .mainImage {
    width: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 20rem;
  }
  .ant-card-head {
    min-height: unset;
    /* min-height: 0; */
    padding: 0 1rem;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        font-weight: 700;
        padding: 8px 0;
      }
    }
  }
  .ant-card-body {
    padding: 0 !important;
    display: flex;
  }
  .content {
    padding: 1rem;
  }
}

/** Description */

.ant-descriptions {
  .ant-descriptions-header {
    padding: 10px 15px;
    margin: 0px !important;
    .ant-descriptions-title {
      font-weight: bold;
    }
  }
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  vertical-align: middle;
  text-align: center;
}

/** Notification */

:where(.css-dev-only-do-not-override-ht6oo7).ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-message {
  margin: 0;
}

/** Radio */

.ant-radio-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/** Upload */

:where(.css-dev-only-do-not-override-dkbvqv).ant-upload-wrapper
  .ant-upload-drag
  .ant-upload {
  padding: 0rem 0.7rem;
}
